import React from 'react';
import styled from 'styled-components';

const ProgressLayout = styled.progress`
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border: none;
  background: #7600ff50;
  z-index: 100000 !important;

  &::-webkit-progress-bar {
    background: #7600ff50;
  }
  &::-webkit-progress-value {
    background: #7600ff;
    background-attachment: fixed;
  }
  &::-moz-progress-bar {
    background: #7600ff;
    background-attachment: fixed;
  }
`;

function ProgressGsap() {
  return (
    <ProgressLayout max="100" value="0"></ProgressLayout>
  )
}

export default ProgressGsap
